@font-face {
  font-family: museo;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local(museo), url(/fonts/Museo700-Regular.otf) format("opentype");
}

/**
 * @license
 *
 * Font Family: Switzer
 * Designed by: Jérémie Hornus
 * URL: https://www.fontshare.com/fonts/switzer
 * © 2023 Indian Type Foundry
 *
 * Font Styles:
 * Switzer Regular
 * Switzer Bold
 *
*/

@font-face {
  font-family: "Switzer-Regular";
  src: url("/fonts/Switzer-Regular.woff2") format("woff2"), url("/fonts/Switzer-Regular.woff") format("woff"),
    url("/fonts/Switzer-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Switzer-Bold";
  src: url("/fonts/Switzer-Bold.woff2") format("woff2"), url("/fonts/Switzer-Bold.woff") format("woff"),
    url("/fonts/Switzer-Bold.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}
