.vjs-seek-to-live-text {
  display: none;
}

.vjs-big-play-button {
  z-index: 2;
}

.clearfix:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.clearfix {
  display: inline-block;
}

html[xmlns] .clearfix {
  display: block;
}

* html .clearfix {
  height: 1%;
}

.vjs-picture-in-picture-control.vjs-control.vjs-button {
  display: none;
}

.video-js .vjs-menu-button-popup .vjs-menu {
  left: unset !important;
  right: -1em;
}

.next-scene-image {
  width: 20px;
  background: url("data:image/svg+xml,%3C%3Fxml version='1.0' %3F%3E%3Csvg height='48' viewBox='0 0 48 48' width='30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='none' fill='white' d='M12 36l17-12-17-12v24zm20-24v24h4V12h-4z'/%3E%3Cpath d='M0 0h48v48H0z' fill='none'/%3E%3C/svg%3E")
    center center no-repeat;
}

.prev-scene-image {
  width: 20px;
  background: url("data:image/svg+xml,%3C%3Fxml version='1.0' %3F%3E%3Csvg height='48' viewBox='0 0 48 48' width='30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='none' fill='white' d='M12 12h4v24h-4zm7 12l17 12V12z'/%3E%3Cpath d='M0 0h48v48H0z' fill='none'/%3E%3C/svg%3E")
    center center no-repeat;
}
