.vjs-fullscreen-control {
  order: 5;
}

.sliderLeft,
.sliderRight {
  position: absolute;
  display: block;
  z-index: 1;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.5);
  /*margin: 76px 0; */
  cursor: pointer;
  transition: background-color 0.3s;
  transition-property: background-color;
  transition-duration: 0.3s;
  transition-timing-function: ease;
  transition-delay: 0s;
}

.sliderLeft:hover,
.sliderRight:hover {
  background-color: #fff;
}

.sliderLeft:after,
.sliderRight:after {
  content: "";
  display: block;
  width: 14px;
  height: 14px;
  border: 0 solid #000;
  border-width: 4px 4px 0 0;
  transform: translate(8px, 10px) rotate(45deg);
}

.sliderLeft:after {
  border-width: 4px 0 0 4px;
  transform: translate(8px, 10px) rotate(-45deg);
}

.textareabutton {
  cursor: pointer;
  margin-top: 6px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 4px;
  color: #fff;
  font-weight: 600;
  letter-spacing: 0.02857em;

  background-color: rgb(54, 53, 91);
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.textareabutton-saved {
  background-color: #228b22;
}

.textareabutton-modified {
  background-color: #ff623c;
}
